import React from "react";

import SecondaryLanding from "../../layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import SEO from "../../components/seo/seo";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import FaqAccordion from "../../components/faq/faq-accordion";
import NotificationAlert from "../../components/notifications/notification-alert";

const Troubleshoot = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Troubleshoot"
    }
  ];

  const faqAccordionData = {
    id: "troubleshoot-faq-accordion",
    faqCategoryNames: ["Troubleshoot Accordion"]
  };

  const title = "Troubleshooting FAQ",
    description =
      "Read WaFd Bank's list of troubleshooting questions and answers to help clear your cache or resolve issues such as a 431 error when trying to sign in.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/customer-service/troubleshooting"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const notificationData = {
    type: "warning",
    bodyText:
      "If you are experiencing a 431 error when trying to sign in to online banking, clearing your browser cache and cookies will resolve the issue. Please follow the steps below. We apologize for any inconvenience.",
    id: "troubleshoot-notification-alert",
    class: "mb-0"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Troubleshoot</h1>
        <NotificationAlert {...notificationData} />
      </section>
      <FaqAccordion {...faqAccordionData} />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Troubleshoot;
